<template>
  <div class="login">
     <div class="login__title">
       Вход в систему
     </div>
    <div class="login__form">
      <el-form
        :model="form"
        :rules="rules"
        ref="loginForm"
        :show-message="false"
      >
        <el-form-item
          label="Email"
          prop="email"
          :class="{ focused: isActiveInput === 'email' || form.email }"
        >
          <el-input
            v-model="form.email"
            @focus="isActiveInput = 'email'"
            @blur="isActiveInput = ''"
            @keyup.enter="login('loginForm')"
          />
        </el-form-item>
        <el-form-item
          label="Пароль"
          prop="password"
          :class="{ focused: isActiveInput === 'password' || form.password }"
        >
          <el-input
            v-model="form.password"
            @focus="isActiveInput = 'password'"
            @blur="isActiveInput = ''"
            @keyup.enter="login('loginForm')"
            show-password
          />
        </el-form-item>
      </el-form>
      <router-link class="login__form-link" to="/forgot-password">
        Забыли пароль?
      </router-link>
      <div class="login__form-button">
        <ButtonElement text="Авторизация" @click="login('loginForm')"/>
      </div>
    </div>
    <div class="login__footer">
      Нет аккаунта? <router-link to="/registration">Зарегистрироваться</router-link>
    </div>
  </div>
</template>

<script>
import ButtonElement from '../components/elements/ButtonElement'
import { AlertNotify } from '@/mixin/alert_notify'

export default {
  name: 'Login',
  mixins: [AlertNotify],
  components: {
    ButtonElement,
  },
  data() {
    return {
      isActiveInput: '',
      rememberMe: false,
      form: {
        email: '',
        password: '',
      },
      rules: {
        email: [
          { required: true, message: 'Введите Email', trigger: 'blur' },
          { type: 'email', message: 'Введите  корректный Email', trigger: ['blur', 'change'] }
        ],
        password: [
          { required: true, message: 'Введите пароль', trigger: ['blur', 'change'] },
          { min: 6, message: 'Пароль должен содержать не менее 6 символов', trigger: ['blur', 'change'] }
        ],
      },
    }
  },
  methods: {
    login (form) {
      this.$refs[form].validate(valid => {
        if(valid) {
          const data = this.form
          this.$store.dispatch('Auth/login', data)
          .then(() => {
            let link = localStorage.getItem('tarifLink')
            if(link) {
              localStorage.removeItem('tarifLink')
              this.$router.push(link)
            } else {
              this.$router.push('/projects')
            }
          })
          .catch(err => {
            this.errorNotify(err.response.data.errors)
          })
        }
      });
    },
  }
}
</script>

<style lang="scss">
@import '../assets/styles/helpers/variables';
@import '../assets/styles/helpers/mixin';

.login {
  background: $basicWhite;
  box-shadow: 0 6px 64px rgba(0, 0, 0, 0.15);
  border-radius: 1.2rem;
  padding-top: 4rem;
  width: 50rem;

  @include below($md) {
    width: 90%;
    padding: 4rem 2rem 0;
  }

  &__title {
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 2.5rem;
    text-align: center;
    margin-bottom: 3rem;
  }

  &__form {
    width: 28rem;
    margin: 0 auto 3.2rem;

    @include below($md) {
      width: 100%;
    }

    &-link {
      font-size: 1.2rem;
      line-height: 1.6rem;
      color: $blue;
      margin-bottom: 3rem;
      display: block;
    }

    &-button {
      display: flex;
      justify-content: center;
      margin-top: 3rem;
    }
  }

  &__footer {
    border-top: 1px solid $lightGrey;
    padding: 2.4rem 0;
    text-align: center;
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: $darkGrey;

    a {
      color: $blue;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
